<template>
	<div id="home">
		<div class="home-box">


			<div class="Roll">
				<div class="Roll_item" v-for="(item,index) in Headlines" :key="index" @click="toWelfareInfo(item.id)">
					<div class="Roll_left">
						<p class="Roll_tit">{{item.name | GuoLvOne}}</p>
						<span class="JG">账户至少充值{{item.min_recharge}}C币</span>
						<div class="Roll_right">
							<div class="Roll_pic">
								<img :src="item.box_records[0].cover" alt="">
							</div>
						</div>
						<div class="DJS">
							<van-count-down :time="new Date(item.end_time).getTime() - new Date().getTime()" format="DD 天 HH 时 mm 分">
								<template #default="timeData">
									<span class="block" v-text="timeData.days < 10 ? '0'+timeData.days : timeData.days"></span>
									<span class="colon">:</span>
									<span class="block" v-text="timeData.hours < 10 ? '0'+timeData.hours : timeData.hours"></span>
									<span class="colon">:</span>
									<span class="block" v-text="timeData.minutes < 10 ? '0'+timeData.minutes : timeData.minutes"></span>
								</template>
							</van-count-down>
						</div>
						<div class="award_bean"><img src="@/assets/images/PublicImg/JinBi.png" alt="">{{item.award_bean}}</div>
					</div>
				</div>
			</div>

			<div class="home-List-box">
				<div v-for="(type,key) in HotList" :key="key">
					<div class="Title">
						<p>{{type.name}}</p>
					</div>
					<div class="home-List">
						<div class="home-list-item" v-for="(item,index) in type.list" :key="index" @click="ToOpen(item.id)">
							<div class="weapon_cover">
								<div class="pic">
									<img v-lazy="item.cover">
								</div>
								<img :src="item.weapon_cover" alt="">
							</div>
							<p>{{item.name}}</p>
							<div class="Blind_btn">
								<img src="@/assets/images/PublicImg/JinBi.png" alt="">{{item.bean}}
							</div>
							<div class="open_btn">
								<img src="@/assets/images/PublicImg/box.png" alt=""><br>OPEN
							</div>
						</div>
					</div>
				</div>

			</div>

		</div>




	</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from 'vuex'
	import {
		GetHotBox,
		Rooms,
		PersonalInfo
	} from '@/network/api.js'
	export default {
		name: 'Home',
		data() {
			return {

				HotList: [],
				BladeList: [],
				recruitList: [],
				classicList: [],
				vitalityList: [],
        returnbox: new Audio(require('@/assets/audios/returnbox.mp3')), //开奖过程声音
				hotboxlist: [],
				time: 10000,
				Headlines: [],
				soulList:[],
				XyList:[]
			}
		},


		created() {
			this.ObtainHotBox()
			this.GetRooms()
		},



		filters: {
			GuoLvOne(val) {
				if (val.length > 14) {
					return val.substr(0, 14) + '...'
				} else {
					return val
				}
			},
			StyChoice(val) {
				if (val == '1') {
					// return 'border-bottom-color:#e6ba1c;background-image: linear-gradient(to top, rgba(230, 186, 28,.5), transparent)'
					return 'background: url(' + require('@/assets/images/Roll/back_huang.png') +') no-repeat center bottom;background-size: contain;'
				} else if (val == '2') {
					// return 'border-bottom-color:#d32ce6;background-image: linear-gradient(to top, rgba(211, 44, 230,.5), transparent)'
					return 'background: url(' + require('@/assets/images/Roll/back_fen.png') +') no-repeat center bottom;background-size: contain;'
				} else if (val == '3') {
					// return 'border-bottom-color:#834fff;background-image: linear-gradient(to top, rgba(131, 79, 255,.5), transparent)'
					return 'background: url(' + require('@/assets/images/Roll/back_lan.png') +') no-repeat center bottom;background-size: contain;'
				} else if (val == '4') {
					// return 'border-bottom-color:#4b69ff;background-image: linear-gradient(to top, rgba(75, 105, 255,.5), transparent)'
					return 'background: url(' + require('@/assets/images/Roll/back_hui.png') +') no-repeat center bottom;background-size: contain;'
				} else {
					// return 'border-bottom-color:#bbbbbb;background-image: linear-gradient(to top, rgba(187, 187, 187,.5), transparent)'
					return 'background: url(' + require('@/assets/images/Roll/back_hui.png') +') no-repeat center bottom;background-size: contain;'
				}
			}
		},

		methods: {

			//热门宝箱
			ObtainHotBox() {
				GetHotBox().then((res) => {
					this.HotList = res.data.data.hotboxlist
				})
			},
			//开箱
			ToOpen(id) {
        this.returnbox.play()//播放声音
				this.$router.push({
					path: '/OpeningBox',
					query: {
						id: id
					}
				})
			},

			//房间
			GetRooms() {
				Rooms('1','1','1').then((res) => {

					let RoomList = res.data.data.data
					// console.log(RoomList)
					// function compare(property) {
					// 	return function(a, b) {
					// 		let value1 = Number(a[property]);
					// 		let value2 = Number(b[property]);
					// 		return value1 - value2
					// 	}
					// }
					let haveHand = RoomList.filter((item) => {
						return item.status_alias == '进行中'
					})
					// console.log(haveHand)
					this.Headlines = haveHand.slice(0, 3)
					// console.log(this.Headlines)

				})
			},

			//前往房间
			toWelfareInfo(id) {
				this.$router.push({
					path: '/room',
					query: {
						id: id
					}
				})
			},

			...mapMutations([
				'User',
				'GetIndividualsShow',
				'GetRegisterShow',
				'GetSignInShow'
			])
		},

		computed: {
			...mapState([
				'user',
				'IndividualsShow',
				'RegisterShow',
				'SignInShow'
			])
		}

	}
</script>
<style lang="scss">
	.home-box {
		width: 14rem;
		padding-top: 0.4rem;
		.Roll{
			width: 98%;
			display: flex;
			justify-content: space-between;
			margin: 50px auto 0;
			.Roll_item{
				width: 30%;
				padding: .2rem;
				box-sizing: border-box;
				cursor: pointer;
				transition: .3s;
				border:3px #b46add solid;
				background:none;
				box-shadow: 0 0 50px rgba($color: #7426b2, $alpha: 0.8) inset;
				&:hover{
					background: linear-gradient(0deg, #b46add, #7731af);
				}
				.award_bean{
					float:right;
					font-size:0.22rem;
					line-height:0.35rem;
					color:#ffd926;
					font-weight:500;
					img{
						width:0.36rem;
						float:left;
						margin-right:5px;
					}
				}
				.Roll_left{
					width: 100%;
					position: relative;
					.Roll_tit{
						font-size: .25rem;
						margin-top: .1rem;
					}
					.JG{
						display: block;
						font-size: .14rem;
						height: .4rem;
						line-height: .4rem;
						border-radius: .04rem;
						img{
							vertical-align: middle;
							width: .25rem;
						}
					}

					.DJS{
						background-size:100% 80%;
						box-sizing: border-box;
						float:left;
						.van-count-down{
							height: 100%;
							display: flex;
							padding-top: .17rem;
							justify-content: center;
							.colon {
							   display: inline-block;
							   margin: 0 4px;
							   color: #fff;
							 }
							 .block {
							   display: inline-block;
							   width: 22px;
							   color: #fff;
							   font-size: .18rem;
							   text-align: center;
							 }
						}

					}

				}

				.Roll_right{
					width: 100%;
					height: 100%;
					position: relative;
					.Roll_pic{
						width: 100%;
						height: 1.5rem;
						display: flex;
						justify-content: center;
						align-items: center;
						img{
							max-width: 100%;
							max-height: 100%;
						}
					}
					p{
						position: absolute;
						right: -.1rem;
						bottom: .1rem;
						width: 1.3rem;
						height: .4rem;
						background: #212936;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: .06rem;
						img{
							width: .25rem;
							height: .25rem;
							vertical-align: middle;
						}
					}
				}
			}
		}
		.home_top {
			width: 100%;
			height: 2.25rem;
			display: flex;
			justify-content: space-between;

			.swiper {
				width: 4.8rem;
				height: 2.25rem;
				border-radius: 6px;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					vertical-align: middle;
				}
			}

			.Headlines-list {
				width: 100%;
				display: flex;
				justify-content: space-between;

				.Headlines-item {
					width: 3.6rem;
					height: 2.25rem;
					cursor: pointer;
					background: url(../../assets/images/public/back_pub.jpg) no-repeat;
					background-size: cover;
					border-radius: 0.1rem;
					padding: 0.1rem;
					box-sizing: border-box;
					position: relative;

					.top {
						display: flex;
						align-items: center;

						img {
							width: .5rem;
							height: .5rem;
							border-radius: 50%;
							margin-right: .1rem;
						}

						.describe {
							.name {
								font-size: .16rem;
								font-weight: bold;
							}

							p {
								font-size: .14rem;
							}
						}
					}

					.Roll_Goods {
						width: 100%;
						height: .8rem;
						margin-top: 10px;
						display: flex;
						box-sizing: border-box;
						justify-content: space-around;
						flex-wrap: wrap;
						overflow: hidden;

						.Roll_Goods_item {
							width: 30%;
							height: .8rem;
							border-bottom: 2px solid transparent;
							box-sizing: border-box;

							.pic {
								width: 90%;
								height: 90%;
								margin: .02rem auto 0;
								display: flex;
								justify-content: center;
								align-items: center;
								background-position: center;
								background-repeat: no-repeat;
								background-size: contain;

								img {
									max-width: 90%;
									max-width: 90%;
								}
							}
						}
					}

					.countDown {
						margin-top: -.15rem;
						text-align: center;

						.colon {
							display: inline-block;
							width: 0.4rem;
							height: 0.3rem;
							text-align: center;
							color: #fff;
							font-size: 0.44rem;
							line-height: 1rem;
						}

						.block {
							display: inline-block;
							width: 0.6rem;
							height: 0.4rem;
							background: rgba(48, 57, 81, 0.8);
							border-top: 1px solid #49587e;
							color: #fff;
							font-size: 0.22rem;
							line-height: 0.4rem;
							letter-spacing: 0.4em;
							padding-left: 0.08rem;
							text-align: center;
							background-size: cover;
							box-sizing: border-box;
							border-radius: 0.04rem;
						}
					}
				}
			}


		}


		.home-List-box {
			.Title {
				margin: 0.5rem auto 0 0;
			}

			.home-List {
				width: 100%;
				display: flex;
				flex-wrap: wrap;

				.home-list-item {
					width: 18%;
					cursor: pointer;
					margin: 0.1rem 1%;
					position: relative;
					background: linear-gradient(180deg, #6e2c9b,#864aaf);
					padding:0.2rem 0;
					.weapon_cover {
						width: 2.3rem;
						height: 2rem;
						margin: 0 auto;
						display: flex;
						justify-content: center;
						align-items: center;
						position: relative;
						img {
							max-width: 100%;
							max-height: 100%;
							transition: .3s;
						}
					}

					.pic {
						width: 2rem;
						height: 2rem;
						position: absolute;
						left: 0.35rem;
						top: -0.4rem;
						display: flex;
						justify-content: center;
						align-items: center;
						z-index: 10;

						img {
							max-width: 100%;
							max-height: 100%;
							transition: .3s;
						}
					}

					p {
						height: 20px;
						font-size: 0.2rem;
						line-height: 25px;
						color: #ddd;
						text-align: center;
					}

					.Blind_btn {
						margin:0.1rem 0 0;
						text-align: center;
						font-size:0.18rem;
						color:#ffd926;
						line-height:0.36rem;
						font-weight: 500;
						img{width:0.3rem;transform: translateY(0.08rem);}
					}
					.open_btn{
						text-align: center;
						font-weight: 500;
						display:none;
						img{width:0.45rem;}
					}

					&:hover {
						background: linear-gradient(180deg, #d85dce,#7a3ac8);
						p{
							display:none;
						}
						.pic {
							img {
								transform: translateY(-20px);
							}
						}
						.Blind_btn{
							display:none;
						}
						.open_btn{
							display:block;
						}
					}
				}
			}
		}

	}
</style>
